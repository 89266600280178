<template>
  <div>
    <!-- input Recherche -->
    <div>
      <!-- <div
        v-if="showAlert || user.role === 'admin'"
        class="alert alert-warning"
        style="color: red;"
      >
        Vous avez une facture impayée avec Direx. Vous devez la payer.
      </div> -->
      <!-- Rest of your component -->
    </div>
    <b-row>
      <b-col>
        <b-form-group
          v-if="user.role === 'admin' || user.role === 'manager'"
          label="Fournisseur"
          label-for="Fournisseur"
        >
          <v-select
            v-model="supplier1"
            :clearable="false"
            placeholder="Fournisseur"
            label="username"
            :options="suppliers"
            @input="getInvoiceBySupplier()"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group v-if="user.role === 'admin'">
            <b-button
              :to="{ name: 'invoice-add' }"
              variant="gradient-primary"
              class="ml-1 d-inline-block"
            >
              <feather-icon icon="PlusIcon" />
              <span class="ml-1">Ajouter facture</span>
            </b-button>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :current-page="currentPage"
      :columns="tableColumns"
      :rows="invoices"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          <qrcode-vue
            style="display:inline"
            :value="props.row.id.toString()"
            :size="size"
            level="H"
          />
          <p class="ml-2">
            {{ props.row.id }}
          </p>

        </div>
        <!-- Column: Name -->
        <div
          v-else-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-info"
              :to="{
                name: 'invoice-show',
                params: { id: props.row.id }
              }"
            > <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap "> de {{ count }} entrées
            </span>
          </div>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageNext(currentPage)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormSelect,
  BSpinner, BTooltip, BFormGroup, BButton,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import storeAuth from '@/store/store'
import QrcodeVue from 'qrcode.vue'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    QrcodeVue,
    vSelect,
  },
  props: {
    supplier: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      currentPage: 1,
      count: 0,
      size: 60,
      isLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Code',
          field: 'id',
        },
        {
          label: 'Fournisseur',
          field: 'supplier.username',
        },
        {
          label: 'Dépôt',
          field: 'repository',
        },
        {
          label: 'N° colis livrées',
          field: 'nb_delivered',
        },
        {
          label: 'N° colis retour',
          field: 'nb_return',
        },
        {
          label: 'Total TTC',
          field: 'total_ttc',
          formatFn: value => `${value} TND`,
        },
        {
          label: 'Frais de livraison',
          field: 'total_delivery',
          formatFn: value => `${value} TND`,
        },
        {
          label: 'Net a payer',
          field: 'total',
          formatFn: value => `${value} TND`,
        },
        {
          label: 'Date | Heure',
          field: 'created_at',
          formatFn: this.formatFn,
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      invoices: [],
      user: {},
      searchTerm: '',
      suppliers: [],
      supplier1: '',
      showAlert: false,

    }
  },
  computed: {
    tableColumns() {
      if (this.user.role === 'admin') {
        return this.columns
      } if (this.user.role === 'manager') {
        return this.columns.filter(column => ['id', 'supplier.username', 'nb_delivered', 'nb_return', 'total_ttc', 'total', 'created_at', 'action'].includes(column.field))
      } if (this.user.role === 'supplier') {
        return this.columns.filter(column => ['id', 'nb_delivered', 'nb_return', 'total_ttc', 'total', 'total_delivery', 'created_at', 'action'].includes(column.field))
      }
      return []
    },
  },

  created() {
    this.user = storeAuth.state.user
    this.getInvoices(1)
    if (this.user.role === 'admin' || this.user.role === 'manager') {
      this.getSuppliers()
      this.checkUnpaidInvoices()
    }
  },
  methods: {
    async getSuppliers() {
      this.isLoading = true
      const { data } = await axios.get('api/auth/users/suppliers/')
      this.suppliers = data
      this.isLoading = false
    },
    async checkUnpaidInvoices() {
      try {
        const response = await axios.get('/api/invoices/check-unpaid-invoices/')
        this.showAlert = response.data.has_unpaid_invoices
      } catch (error) {
        console.error('Error checking unpaid invoices:', error)
        if (error.response && error.response.status === 403) {
          console.error('Permission denied. User is not an admin.')
        }
      }
    },
    async pageNext(currentPage) {
      if (this.supplier1 !== '') {
        this.getInvoiceBySupplier(currentPage)
      } else {
        this.getInvoices(currentPage)
      }
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    // get voiture
    async getInvoices(currentPage) {
      this.isLoading = true
      const { data } = await axios.get('api/invoices/', {
        params: {
          p: currentPage,
        },
      })
      this.count = data.count
      this.invoices = data.results
      this.isLoading = false
    },
    async getInvoiceBySupplier(currentPage) {
      this.num = ''
      this.repository = {}
      this.isLoading = true
      const { data } = await axios.get('api/invoices/', {
        params: {
          p: currentPage,
          supplier: this.supplier1.id,
        },
      })
      this.invoices = data.results
      this.count = data.count
      this.isLoading = false
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
